import React, { FC } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Routes } from '../constants/Routes';
import useImages from '../hooks/useImages';
import renderHtml from '../utils/renderHtml';
import Title from '../components/title';
import Image from 'gatsby-image';
import { IFluidObject } from 'gatsby-background-image';
import styled from 'styled-components';
import Icon from '../components/Icon';

const ERWACHSENE_QUERY = graphql`
  {
    allContentfulKurs(filter: { kategorie: { eq: "Erwachsene" } }) {
      nodes {
        id
        titel
        order
        kategorie
        beschreibung {
          json
        }
        bilder {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;

const Grid = styled.div`
  @media screen and (min-width: 500px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
    width: 95%;
    margin: 0 auto;
    height: 100%;
    grid-gap: 1rem;
    justify-items: center;
    margin-top: 2rem;
  }
`;

type Kurs = {
  id: string;
  titel: string;
  bilder: any;
  order: number;
  category: 'kinder' | 'erwachsene';
};

const ErwachsenePage: FC = () => {
  const kurse: Kurs[] = useStaticQuery(ERWACHSENE_QUERY).allContentfulKurs.nodes;
  
  return (
    <Layout location={Routes.ERWACHSENE}>
      <SEO title="Erwachsene" />
      <div className="pt-8 flex justify-center flex-col items-center">
        <Title className="mb-4 text-center">Erwachsene</Title>
        <Grid>
          {kurse
          .slice()
          .sort((a, b) => a.order - b.order)
          .map(kurs => (
            <Link
              to={'/erwachsene/' + kurs.titel}
              key={kurs.id}
              className="transition-all transition-250 hover:shadow-xl max-w-md mb-8 rounded shadow-lg"
              style={{ width: '22rem' }}
            >
              <Image className="w-full h-64" fluid={kurs.bilder[0].fluid} alt={kurs.titel} />
              <div className="px-6 py-4 flex justify-center items-center">
                <Link
                  to={'/erwachsene/' + kurs.titel}
                  className="pb-2 inline-block font-bold text-xl text-center underline mt-2 cursor-pointer"
                >
                  {kurs.titel}
                </Link>
              </div>
            </Link>
          ))}
        </Grid>
      </div>
    </Layout>
  );
};

export default ErwachsenePage;
